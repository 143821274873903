import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { PrivateRoute } from "utils/PrivateRoute";
// import Home from "pages/Home";
const NotFound = React.lazy(() => import("pages/NotFound"));
const Notifications = React.lazy(() => import("pages/Notifications"));
const AlarmView = React.lazy(() => import("pages/AlarmView"));
const Layout = React.lazy(() => import("components/Layout/Layout"));
const Login = React.lazy(() => import("pages/Login"));
const Register = React.lazy(() => import("pages/Register"));
const Success = React.lazy(() => import("./pages/Success/Success"));
const CreateCompany = React.lazy(() => import("pages/CreateCompany"));
const Settings = React.lazy(() => import("pages/Settings/Settings"));
const Company = React.lazy(() => import("pages/Company"));
const Alarm = React.lazy(() => import("pages/Alarm"));
const Trail = React.lazy(() => import("pages/Trail"));
const LocationVehicle = React.lazy(() => import("pages/LocationVehicle"));
const Conversation = React.lazy(() => import("pages/Conversation"));
const Vehicle = React.lazy(() => import("pages/Vehicle"));
const DriversOne = React.lazy(() => import("pages/DriversOne"));
const Drivers = React.lazy(() => import("pages/Drivers"));
const ReportView = React.lazy(() => import("pages/ReportView"));
const Reports = React.lazy(() => import("pages/Reports"));
const Dashboard = React.lazy(() => import("pages/Dashboard"));
const VehicleInfoTraccar = React.lazy(() => import("pages/VehicleInfoTraccar"));
const AdminDashboard = React.lazy(() => import("adminPages/Dashboard"));
const UserView = React.lazy(() => import("adminPages/UserView"));
const AdminConversations = React.lazy(() => import("adminPages/Conversations"));
const Links = React.lazy(() => import("adminPages/Links"));
const AdminLayout = React.lazy(() => import("components/adminLayout/Layout"));
const AdminReportView = React.lazy(() => import("adminPages/ReportView"));
const Collections = React.lazy(() => import("adminPages/Collections"));
const QrsCodes = React.lazy(() => import("adminPages/QrsCodes"));
const Clients = React.lazy(() => import("pages/Clients"));
const ClientsRoutes = React.lazy(() => import("pages/Routes"));

const ProjectRoutes = () => {
  return (
    <React.Suspense
      fallback={
        <>
          <div className="loader-container">
            <div className="loader"></div>
          </div>
        </>
      }
    >
      <Router>
        <Routes>
          {/* ===============  PROTECTED  ================= */}
          {/* user routes */}
          <Route path="/" element={<Layout />}>
            <Route element={<PrivateRoute />}>
              {/* <Route path="/" element={<Home />} /> */}
              <Route path="/" element={<Dashboard />} />
              <Route path="/reports" element={<Reports />} />
              <Route path="/reports/driver/:id" element={<Reports />} />
              <Route path="/reports/vehicle/:id" element={<Reports />} />
              <Route path="/reportview/:id" element={<ReportView />} />
              <Route path="/drivers" element={<Drivers />} />
              <Route path="/clients" element={<Clients />} />
              <Route path="/routes" element={<ClientsRoutes />} />
              <Route path="/driversone" element={<DriversOne />} />
              <Route path="/vehicle" element={<Vehicle />} />
              <Route path="/map" element={<LocationVehicle />} />  
              <Route path="/conversation" element={<Conversation />} />
              <Route path="/setting" element={<Settings />} />
              <Route path="/company" element={<Company />} />
              <Route path="/alarm" element={<Alarm />} />
              <Route path="/trail" element={<Trail />} />
              <Route path="/notifications" element={<Notifications />} />
              <Route path="/alarmview/:id" element={<AlarmView />} />
              <Route path="/vehicle/info/:idDevice/:idVehicle" element={<VehicleInfoTraccar />} />
            </Route>
          </Route>

          {/* admin routes */}
          <Route path="/" element={<AdminLayout />}>
            <Route element={<PrivateRoute />}>
              <Route path="/admin" element={<AdminDashboard />} />
              <Route path="/admin/user/:id" element={<UserView />} />
              <Route path="/admin/reportview/:id" element={<AdminReportView />} />
              <Route path="/admin/conversations" element={<AdminConversations />} />
              <Route path="/admin/links" element={<Links />} />
              <Route path="/admin/collections" element={<Collections />} />
              <Route path="/admin/qrcodes" element={<QrsCodes />} />
            </Route>
          </Route>

          <Route path="/company/create" element={<CreateCompany />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/success" element={<Success />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </React.Suspense>
  );
};
export default ProjectRoutes;
