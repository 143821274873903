import { createContext, useReducer } from "react";

const INITIAL_STATE = {
  companyName: "",
  registrationNumber: "",
  email: "",
  phone: "",
  website: "",
  address: "",
  otherData: "",
  userId: "",
};

const CompanyContext = createContext(INITIAL_STATE);

const CompanyReducer = (state, action) => {
  switch (action.type) {
    case "CHANGE_INPUT":
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    default:
      return state;
  }
};

export const CompanyProvider = (props) => {
  const [state, dispatchCompany] = useReducer(CompanyReducer, INITIAL_STATE);

  return (
    <CompanyContext.Provider value={{ state, dispatchCompany }}>
      {props.children}
    </CompanyContext.Provider>
  );
};

export default CompanyContext;
