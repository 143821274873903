import { ProSidebarProvider } from "react-pro-sidebar";
import React from "react";
import Routes from "./Routes";
import { QueryClient, QueryClientProvider } from "react-query";
import { AuthProvider } from "utils/Reducers/AuthReducer";
import { VehicleProvider } from "utils/Reducers/VehicleReducer";
import { DriverProvider } from "utils/Reducers/DriverReducer";
import { RegProvider } from "utils/Reducers/RegReducer";
import { CompanyProvider } from "utils/Reducers/CompanyReducer";

function App() {
  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <CompanyProvider>
        <RegProvider>
          <AuthProvider>
            <VehicleProvider>
              <DriverProvider>
                <ProSidebarProvider>
                  <Routes />
                </ProSidebarProvider>
              </DriverProvider>
            </VehicleProvider>
          </AuthProvider>
        </RegProvider>
      </CompanyProvider>
    </QueryClientProvider>
  );
}

export default App;
